body {
  --tw-bg-opacity: 1;
  background-color: rgb(183 169 143 / var(--tw-bg-opacity));
}

.Wrapper {
  max-width: 5em;
  overflow: hidden;
  max-height: 9em;
}

.TextDisplay:first-child {
  display: inline-block; /* modèle de boîte en ligne */
  padding-right: 0.2em; /* un peu d'espace pour la transition */
  padding-left: 100%; /* placement à droite du conteneur */
  white-space: nowrap; /* pas de passage à la ligne */
  animation: show-text 3s linear infinite running;
}

@keyframes show-text {
  0% {
    transform: translate3d(-100%, 0, 0); /* position initiale à droite */
  }
  100% {
    transform: translate3d(0, 0, 0); /* position finale à gauche */
  }
}

.Section1 {
  word-wrap: break-word;
  width: 0.5em;
  height: auto;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  font-size: 4em;
  color: rgba(0, 0, 0, 0.1);
  letter-spacing: -1px;
}
.Section2 {
  writing-mode: vertical-rl;
}
.ContactCss {
  background-image: url(../src/assets/contact/contact_1.png);
  background-size: cover;
}
